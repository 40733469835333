<template>
    <div class="py-2 position-relative" style=" overflow: hidden; background-color: #101585;border-radius:4px;">
       <div class="bg-image"></div>
  <div class="w-100 container-fluid  announcement-banner " style=" ">
    <div class="d-flex justify-content-between">
        <div class="">  
            <div class="announcement-heading">  November Sale 🎉 <span class="announcement-offer">Get 40% OFF</span> on all Quizell plans!</div>
            <div class="announcement-validate">Unlock powerful features and grow your business today. Don't miss out! <a href="https://www.wix.com/apps/upgrade/f67f3bd5-3d73-486c-9bb2-618adcc19fb9" target="_blank" class="announcement-offer upgradeLink">Grab this offer</a></div>
        </div>
        <div class="d-flex align-items-center gap">
            <div class="text-center">
                <div class="announcement-heading">{{ timeRemaining?.days }}</div>
                <div class="announcement-validate">Days</div>
            </div>
            <div class="announcement-heading">:</div>
            <div class="text-center">
                <div class="announcement-heading">{{ timeRemaining?.hours }}</div>
                <div class="announcement-validate">Hours</div>
            </div>
            <div class="announcement-heading">:</div>
            <div class="text-center">
                <div class="announcement-heading">{{ timeRemaining?.minutes }}</div>
                <div class="announcement-validate">Minutes</div>
            </div>
        </div>
    </div>
    
  </div>
  
</div>
</template>

<script>
export default {
data(){
    return{
        announcement:{
            status:true,
            heading:'November Sale ',
            offer:'Up to 50% OFF',
            detail:'Offer is valid until 04 December 2024 at 12:00 AM UTC.',
            timer:332342323244
        },
        deadline: new Date(Date.UTC(2024, 11, 4, 0, 0, 0)), // December 4, 2024, 00:00 UTC
      intervalId: null,
      timeRemaining: null,
    }
},
mounted() {
    this.startCountdown();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    startCountdown() {
      this.updateTimeRemaining();
      this.intervalId = setInterval(this.updateTimeRemaining, 60000); // Update every minute
    },
    updateTimeRemaining() {
      const now = new Date();
      const difference = this.deadline - now;
      if (difference <= 0) {
        clearInterval(this.intervalId);
        this.timeRemaining = { days: "00", hours: "00", minutes: "00" };
        return;
      }
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      this.timeRemaining = {
        days: days < 10 ? `0${days}` : days.toString(),
        hours: hours < 10 ? `0${hours}` : hours.toString(),
        minutes: minutes < 10 ? `0${minutes}` : minutes.toString(),
      };
    },
  },
}
</script>

<style>
.announcement-heading{
    font-size: 20px;
    color: white;
    font-weight: 500;
}
.announcement-validate{
    color: white;
    font-size: 12px;
}
.announcement-offer{
    color: #8dffad;
}
.bg-image{
    background-image: url('https://static.parastorage.com/services/marketplace-standalone/1.5728.0/media/sale-banner-background.4f5b527b.svg');
    background-position-x: max(150px, 100% + 24px);
    background-repeat: repeat-y;
    height: 744px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-197px);
}

.upgradeLink{
  color: #8dffad;
  position: relative;
  cursor: pointer;
}
.upgradeLink:hover{
  
  color: #8dffad;
}
</style>